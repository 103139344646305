import React from 'react';
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceManyField,
  useTranslate,
  ReferenceField,
  usePermissions,
  DeleteWithConfirmButton,
} from 'react-admin';
import { Grid, Typography } from '@material-ui/core';

import { convertTheValueToBytes } from '../../Utils/convertTheValueToBytes';
import { FormGeneral } from '../../styles';
import { DocumentType } from '../../Utils/enums';
import TablePlaceholder from '../../Components/TablePlaceholder';
import DocumentIconNameField from './DocumentIconNameField';
import DocumentViewButton from './DocumentViewButton';
import DocumentDeleteButton from './DocumentDeleteButton';
import DocumentEditButton from './DocumentEditButton';

const DocumentList = (props) => {
  const classes = FormGeneral();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const { id } = props.record;
  const segment = props.segment;
  const reserved = Boolean(props.private);
  const filter = {
    segment,
    related: id,
  };

  filter[reserved ? 'private' : 'private_ne'] = true;

  const showRemoveButton = ['LST-ORAG-1', 'LST-SF-1', 'SF-LST-13'].includes(
    props.record?.loanApplicationState.code_id
  );

  return (
    <ReferenceManyField
      reference='documents'
      target='person'
      filter={filter}
      sort={{ field: 'archived', order: 'ASC' }}
      perPage={-1}
      addLabel={false}
      className={classes.width100}
    >
      <Datagrid
        empty={<TablePlaceholder title='Documentos' />}
        rowStyle={(record) => ({
          opacity: record.archived ? 0.4 : 1,
        })}
      >
        <DocumentIconNameField
          source='documents[0].name'
          emptyText='-'
          label={translate('resources.documents.fields.name')}
          sortable={false}
        />
        <ReferenceField
          source='documentType'
          resource='people'
          reference='document-types'
          className={classes.width25}
          link={false}
          emptyText='-'
          label={translate('resources.documents.fields.documentType')}
        >
          <ChipField source='name' />
        </ReferenceField>
        <FunctionField
          render={(record) =>
            convertTheValueToBytes(record?.documents[0]?.size)
          }
          source='size'
          label={translate('resources.documents.fields.size')}
          emptyText='-'
          sortable={false}
        />
        <DateField
          source='created_at'
          showTime={false}
          emptyText='-'
          locales='es-MX'
          label={translate('resources.documents.fields.created_at')}
        />
        <FunctionField
          render={(record) => {
            return (
              <Grid container>
                <Grid item xs={3}>
                  <DocumentViewButton record={record} />
                </Grid>
                {permissions?.['document']?.update.enabled &&
                  !record?.archived && (
                    <Grid item xs={3}>
                      <DocumentEditButton record={record} />
                    </Grid>
                  )}
                {permissions?.['document']?.delete.enabled && (
                  <Grid item xs={3}>
                    <DocumentDeleteButton record={record} />
                  </Grid>
                )}
                {showRemoveButton && (
                  <Grid item xs={3}>
                    <DeleteWithConfirmButton
                      className={classes.deleteButton}
                      record={record}
                      label=''
                      redirect={false}
                      confirmContent={
                        <>
                          <Typography>
                            Estás seguro de querer eliminar el archivo{' '}
                            {record?.documents[0].name}
                          </Typography>
                          <Typography>
                            Esta operación no se puede deshacer.
                          </Typography>
                        </>
                      }
                      confirmTitle='Eliminar documento'
                    />
                  </Grid>
                )}
              </Grid>
            );
          }}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default DocumentList;
